import styled from "@emotion/styled";
import { FOLD_TEST_MAX_WIDTH } from "constants/vp-sizes";
export const StyledLottieAnimation = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;

  svg path {
    fill: ${({ color })=>color};
    stroke: ${({ color })=>color};
  }

  @media (min-width: ${FOLD_TEST_MAX_WIDTH}) {
    &.above-the-fold {
      justify-content: flex-end;
    }
  }
`;
