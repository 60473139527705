import styled from "@emotion/styled";
import colors from "styles/colors.js";
export const StyledReview = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-word;
  text-align: center;

  .review-content {
    margin-top: 16px;
    flex-grow: 1;

    .line {
      font-size: 1rem;
      line-height: 30px;
    }
  }

  .reviewer-name {
    margin-top: 24px;
    font-size: 0.875rem;
    color: ${colors["jaco"]};
    font-style: italic;
  }

  .reviewed-on {
    font-size: 0.8125rem;
    margin-top: 16px;
    color: ${colors["jaco"]};
  }
`;
