import styled from "@emotion/styled";
export const StyledStarsComponent = styled.div`
  display: flex;
  justify-content: center;

  .star-icon-wrapper {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;
