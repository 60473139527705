function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
const URL_BASE = process.env.AUTH_PLATFORM_PATH;
const DEFAULT_FETCH_OPTIONS = {
    credentials: "same-origin",
    headers: {
        "Content-Type": "application/json"
    }
};
const baseFetch = (url, options)=>{
    return fetch(url, _object_spread({}, DEFAULT_FETCH_OPTIONS, options));
};
export const sendCookies = (visitorId, cookies)=>{
    return baseFetch(`${URL_BASE}/set_visitor_cookies`, {
        method: "POST",
        body: JSON.stringify({
            visitor_id: visitorId,
            cookies: cookies
        })
    });
};
