import styled from "@emotion/styled";
import { FOLD_TEST_MAX_WIDTH } from "constants/vp-sizes";
export const StyledLottieComponent = styled.div`
  width: 100%;
  height: 100%;

  @media (min-width: ${FOLD_TEST_MAX_WIDTH}) {
    &.above-the-fold {
      width: 50%;
      transform: scale(-1);
    }
  }
`;
