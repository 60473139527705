import { getQueryStringAsHash } from "../utils/url";
import { getCookie } from "/services/cookies-service";
import { BIG_BRAIN_VISITOR_ID } from "/constants/cookies";
const ONELINK_BASE_URL = "https://go.onelink.me/wwJQ";
export const getOnelinkWithTrackingParams = ()=>{
    const url = window.location;
    let finalUrl = ONELINK_BASE_URL;
    // Parse URI, get query string hash
    const queryString = getQueryStringAsHash(url);
    const m_source = queryString.marketing_source || queryString.utm_source;
    const m_banner = queryString.marketing_banner || queryString.utm_banner;
    const visitorId = getCookie(BIG_BRAIN_VISITOR_ID);
    // Indicate that the origin is mobile web.
    finalUrl += "?is_mobile_web=true";
    // Add source if given.
    if (m_source) {
        finalUrl += `&pid=${m_source}`;
    }
    // Add banner if given.
    if (m_banner) {
        finalUrl += `&c=${m_banner}`;
    }
    if (visitorId) {
        finalUrl += `&visitor_id=${visitorId}`;
    }
    return finalUrl;
};
