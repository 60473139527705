import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH, TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledCompaniesLogosComponent = styled.div`
  position: relative;

  .images-gallery-row {
    display: flex;
    justify-content: space-evenly;
    padding: 24px 40px;

    .image-gallery {
      .social-proof-gallery-picture {
        img {
          height: 60px;
        }
      }
    }
  }

  .signup-wrapper {
    margin-top: 48px;
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .signup-wrapper {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .images-gallery-row {
      .image-gallery {
        padding: 8px;
      }
    }
  }
`;
