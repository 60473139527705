import colors from "styles/colors";
import { TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
const _defaultExport = new String(`.text-part-component{.title-lines-wrapper{margin-bottom:24px;b {
        font-weight: 700 !important;
      }
    .title-line{font-size:2.531rem;margin-bottom:8px;font-weight:300;display:block}}.subtitle-lines-wrapper{.subtitle-line{font-size:1.238rem;margin-bottom:16px;display:block}}.text-part-signup-form-wrapper{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;margin:32px 0}.text-part-bullets{.bullet-line{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;margin-bottom:16px;.bullet-line-icon-wrapper{-webkit-box-flex:0;-webkit-flex:0 0 24px;-moz-box-flex:0;-ms-flex:0 0 24px;flex:0 0 24px;margin-right:8px;svg{vertical-align:middle}&.white{svg{path,
              circle {
                stroke: white;
              }
            }}}.bullet-line-content{-webkit-box-flex:1;-webkit-flex:1;-moz-box-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-moz-box-align:center;-ms-flex-align:center;align-items:center;font-size:1rem}}}.second-cta-wrapper{padding-top:24px}}@media(max-width:${TABLET_QUERY_MAX_WIDTH}){.text-part-component{.title-lines-wrapper{.title-line{font-size:1.913rem}}.subtitle-lines-wrapper{.subtitle-line{font-size:1.125rem;line-height:32px}}.text-part-signup-form-wrapper{display:block}.text-part-bullets{display:none}}}`);
_defaultExport.__hash = "fad960dc50a572d5";
export default _defaultExport;
