import styled from "@emotion/styled";
export const StyledReviewsComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-top: 16px;
      font-size: 1.375rem;
      font-weight: 500;
    }
  }

  .reviews-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .review-component-wrapper {
      margin: 40px 32px 0;
    }
  }
`;
