import styled from "@emotion/styled";
export const StyledSocialProofComponent = styled.div`
  .companies-logos-component-wrapper {
    margin-top: 40px;
  }

  .reviews-component-wrapper {
    margin-top: 40px;
  }

  .signup-with-decorations-component-wrapper {
    margin: 71px 0px 64px;
    display: flex;
    justify-content: center;
  }
`;
